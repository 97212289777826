.App {
  background-color: #fafafd;
}


#navBar{
  /* margin: 0;  
  padding: 0px;  
  overflow: hidden; 
  width: 100%; 
  background-color: white;
  border-bottom-width:1px ;
  border-color: rgb(6, 68, 193); */
  
  position: sticky;
  top: 0;
  background-color: yellow;
  padding: 50px;
  font-size: 20px;
}
#zoom{
  transition: all .5s;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  -ms-transition: all .5s;
}

#zoom:hover{
  transform : scale(1.1);
 -moz-transform : scale(1.1);
 -webkit-transform : scale(1.1);
 -o-transform : scale(1.1);
 -ms-transform : scale(1.1);
 cursor:pointer;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}